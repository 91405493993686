import { Controller } from "stimulus";

export default class extends Controller {
  showCurrency(event) {
    var parentElem = $(event.target).closest('.js-tip-rate-options');

    parentElem.find('.js-tip-by-currency').show();
    parentElem.find('.js-tip-by-percent').hide();
  }

  showPercent(event) {
    var parentElem = $(event.target).closest('.js-tip-rate-options');

    parentElem.find('.js-tip-by-currency').hide();
    parentElem.find('.js-tip-by-percent').show();
  }

  openTipSettingsModal(event){
    event.preventDefault();

    $('#tip-settings-modal').modal();
    $('#tip-settings-modal .modal-dialog-loading').show();
    $('#tip-settings-modal .modal-dialog-loaded').hide();

    var modalUrl = $(event.target).data('modalUrl');

    fetch(modalUrl)
      .then(response => response.text())
      .then(function(html){
        $('#tip-settings-modal .modal-dialog-loading').hide();
        $('#tip-settings-modal .modal-dialog-loaded').show();
        $('#tip-settings-modal .modal-dialog-loaded').html(html);

        $('[data-toggle="tooltip"]').tooltip();

        $(".js-toggler-toggle-show-hide-target").each(function() {
          var targetElem = $($(this).data('toggleTarget'));
          $(this).on("click", function () {
            targetElem.toggle();
          });
        });
      });
  }
}