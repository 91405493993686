import { Controller } from "stimulus";

export default class extends Controller {
  setFixedTipAmount(event) {
    $('.js-select-other-tip-amount').hide();

    var tipAmount = Number($(event.target).find('.js-tip-amount').val());
    this.setTipAmount(tipAmount);

    event.preventDefault();
  }

  setOtherTipAmount(event) {
    var otherTipAmount = Number($(event.target).val());
    var controller = this;

    window.currentOtherTipAmount = otherTipAmount;

    setTimeout(() => {
      if(otherTipAmount == window.currentOtherTipAmount) {
        controller.setTipAmount(otherTipAmount);
      }
    }, 250);

    event.preventDefault();
  }

  showOtherTipAmountField(event) {
    $('.js-select-other-tip-amount').show();
    event.preventDefault();
  }
 
  setTipAmount(tipAmount) {
    var baseTotal = Number($('.js-total').data('total'));
    var tipValue = $('.js-tip-value');
    var currencyUnit = tipValue.data('currencyUnit');

    // Processing amount
    var newTotal = tipAmount + baseTotal;

    if(tipAmount > 0){
      $('.js-tip-amount').show();
    } else {
      $('.js-tip-amount').hide();
    }

    tipValue.html(currencyUnit + tipAmount.toFixed(2));
    $('#card-button').data('tip', tipAmount.toFixed(2));
    $('#card-button').prop('disabled', 'disabled');
    $('#stripe-submit-button').prop('disabled', 'disabled');

    $('.js-processing-fee').html('-');

    this.updatePaymentIntent(tipAmount);
  }

  updatePaymentIntent(tipAmount){
    if($('#stripe-submit-button').is('*')){
      var paymentIntentId = $('#stripe-submit-button').data('paymentIntentId');
    } else {
      var paymentIntentId = $('#card-button').data('paymentIntentId');
    }

    var url = new URL(window.location.href);
    var params = new URLSearchParams(url.search);
    var paramsObj = {
      payment_intent_id: paymentIntentId,
      tip: tipAmount
    };

    params.forEach((value, key) => {
      paramsObj[key] = value;
    });

    $.ajax({
      url: window.updatePaymentIntentUrl,
      type: "POST",
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
      },
      data: paramsObj,
      dataType: 'json',
      success: function(data) {
        var tipValue = $('.js-tip-value');
        var currencyUnit = tipValue.data('currencyUnit');

        $('.js-processing-fee').html(currencyUnit + Number(data['processing_fee']).toFixed(2));

        $('#card-button').find('.js-pay-total').html(' ' + currencyUnit + Number(data['display_total']).toFixed(2));
        $('#card-button').prop('disabled', false);

        $('#stripe-submit-button').find('.js-pay-total').html(' ' + currencyUnit + Number(data['display_total']).toFixed(2));
        $('#stripe-submit-button').prop('disabled', false);
      },
      error: function(xhr) {
        console.log("Unable to apply tip. Please try again.");
        $('#card-button').prop('disabled', false);
        $('#stripe-submit-button').prop('disabled', false);
      }
    });

  }
}
