import Tribute from 'tributejs';

$(document).on("turbolinks:load", function () {
  if ($("#campaign-form").is("*")) {
    setBindings();
    loadPreview();
  }

  if ($('#js-segment-select').val()) {
    triggerSegmentCount();
  }

  $('#js-unsub-campaign').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();
    unsubCampaign($(this));
  })

  $('#js-sub-campaign').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();
    subCampaign($(this));
  })

  $('#js-show-subscription-modal').on('click', function(e){
    showSub();
  })

  if ($('.resize-iframe').is('*')) {
    setTimeout(function() {
      resizeIframe();
    }, 500);

    // Sometimes images take a moment to load and change the size of the window
    setTimeout(function() {
      resizeIframe();
    }, 4500);
  }

});

function setBindings() {

  var sendOnceBtns = $('#js-send-once-schedule-btns');
  var recurringDiv = $('#js-campaign-recurring');
  var startAtDiv = $('#js-schedule-once-date');
  var form = $('#campaign-form');

  tributeVars(form.find('.js-content-input'));
  tributeVars(form.find('#campaign_subject'));

  $('#send-once').on('click', function() {
    if ($('#send-once').is(':checked')) {
      sendOnceBtns.show();
      toggleScheduleDiv();
      recurringDiv.hide();
      recurringDiv.find('input, select').prop('disabled', true);
    } else {
      recurringDiv.find('input, select').prop('disabled', false);
      $('.selectpicker').selectpicker("refresh");
      sendOnceBtns.hide();
      startAtDiv.hide();
      startAtDiv.find('input').prop('disabled', true);
      recurringDiv.show();
    }
  });

  $('#js-send-once-schedule-btns input').on('click', function(){
    toggleScheduleDiv();
  });

  $('#js-campaign-frequency-select').on('change', function(){
    toggleFrequencyDivs();
  });

  $('#submit-btn, #submit-and-publish-btn').on('click', function(event){
    var button = $(this);

    button.prop('disabled', true);
    button.html(button.data('disable-with'))

    if (button.attr('id') === 'submit-and-publish-btn') {
      var activeField = form.find('#active-campaign');

      activeField.val( activeField.val() === 'true' ? 'false' : 'true');
    }

    if(validateForm(event, form[0])) {
      form.submit();
    } else {
      button.prop('disabled', false);
    }

  });

  $('.js-preview-tab').on('click', function(){
    loadPreview();
  });
}

function loadPreview() {
  var form = $('#campaign-form');
  var iframe = $('iframe.email-preview-frame');
  var loader = $('.js-loading');

  iframe.addClass('d-none');
  loader.show();

  $.ajax({
    url: iframe.data('url'),
    type: "GET",
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    dataType: 'html',
    data: {
      body: form.find('.js-content-input').parent().find('input').val()
    },
    success: function(response) {
      iframe[0].contentWindow.document.open();
      iframe[0].contentWindow.document.write(response);
      loader.hide();
      iframe.removeClass('d-none');
      resizeIframe();
    },
    error: function(response) { 
      window.notyf.error("Unable to load preview.");
    }
  });
}

function resizeIframe() {
  document.getElementsByClassName('resize-iframe').forEach( (iframe) => {
    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
  });
}

function subCampaign(button) {

  button.prop('disabled', true);

  $.ajax({
    url: button.data('url'),
    type: "POST",
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    dataType: 'json',
    success: function() {
      window.location.reload();
    },
    error: function(response) {
      button.prop('disabled', false);

      if (response.message) {
        var message = response.message;
      } else {
        var message = "Sorry, something went wrong. You were not subscribed.";
      }
      window.notyf.error(message);
    }
  });

}

function unsubCampaign(button) {
  button.prop('disabled', true);

  $.ajax({
    url: button.data('url'),
    type: "PATCH",
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    dataType: 'json',
    success: function() {
      window.location.reload();
    },
    error: function(response) {
      button.prop('disabled', false);

      if (response.message) {
        var message = response.message;
      } else {
        var message = "Sorry, something went wrong. You were not unsubscribed.";
      }
      window.notyf.error(message);
    }
  });
}

function showSub() {
  $.ajax({
    url: $('#js-update-subscription-details').data('url'),
    type: "GET",
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    dataType: 'json',
    success: function(response) {

      $('#js-due-today').html(response.total);
      $('#js-due-renew').html(response.due_at_renew);

      if (response.tax) {
        $('#js-base-price').html(response.base_price);
        $('#js-tax').html(response.tax);

        $('#js-base-totals').removeClass('d-none');
      }

      $('#loading').slideUp('fast', function(){
        $('#success').slideDown('fast');
      });
    },
    error: function(response) {
      $('#loading').slideUp('fast', function(){
        $('#error').slideDown('fast');
      });
    }
  });
}

function toggleScheduleDiv() {
  var startAtDiv = $('#js-schedule-once-date');

  if ($('.js-send-immediately').is(':checked')) {
    startAtDiv.hide();
    startAtDiv.find('input, select').prop('disabled', true);
  } else {
    startAtDiv.find('input, select').prop('disabled', false);
    $('.selectpicker').selectpicker("refresh");
    startAtDiv.show();
  }
}

function toggleFrequencyDivs() {
  var value = $('#js-campaign-frequency-select').val();

  $('.js-frequency-options').addClass('d-none');
  $('.js-frequency-options').find('input, select').prop('disabled', true);

  switch (value) {
    case 'daily':
      break;

    case 'weekly':
      $('#js-campaign-wdays').find('input, select').prop('disabled', false);
      $('#js-campaign-wdays').removeClass('d-none');
      break;

    case 'monthly':
      $('#js-campaign-dom').find('input, select').prop('disabled', false);
      $('#js-campaign-dom').removeClass('d-none');
      break;
  }
}

function triggerSegmentCount() {
  var clientSegmentDiv = $('#js-client-segment-wrapper');

  $.ajax({
    url: clientSegmentDiv.data('url'),
    type: "GET",
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    data: {
      segment_id: $('#js-segment-select').val()
    },
    dataType: 'script',
    error: () => {
      clientSegmentDiv.empty();
    }
  });
}

function tributeVars(element) {
  var dynamic_vars = [
    { value: 'first_name', key: 'Client First Name' },
    { value: 'last_name', key: 'Client Last Name' },
    { value: 'client_email', key: 'Client Email' },
    { value: 'client_phone', key: 'Client Phone' },
    { value: 'company_name', key: 'Company Name' },
    { value: 'company_phone', key: 'Company Phone' }
  ]

  var tribute = new Tribute ({
    trigger: '{{',
    containerClass: 'dropdown-menu',
    itemClass: 'dropdown-item',
    values: dynamic_vars,
    replaceTextSuffix: '}}\n',
    noMatchTemplate: "<span class='ml-2'>No Match Found!</span>"
  });

  tribute.attach(element);
}